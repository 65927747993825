
import {computed, defineComponent, ref, watch} from "vue";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent, IonDatetime,
  IonGrid, IonItem, IonItemDivider,
  IonLabel,
  IonPage,
  IonRow,
  IonSearchbar, isPlatform, onIonViewDidEnter
} from "@ionic/vue";
import {User} from "@/domain/model/user/user.model";
import {useStore} from "vuex";
import ProductionBonusUserListComponent from "@/app/view/bonus/component/ProductionBonusUserListComponent.vue";
import {useBonusApi} from "@/domain/service/api/bonus.api";
import {useUserApi} from "@/domain/service/api/use-user-api";
import _, {map} from "lodash";
import {formatDate} from "@/domain/helper/date.helper";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useBonusMaterialApi} from "@/domain/service/api/bonusMaterial.api";
import {NDataTable, NDatePicker, NSpace} from 'naive-ui';
import {ProductionDailyUserBonus, ProductionUserBonus} from '@/domain/model/productionBonus/bonus.model';
import {uuid4} from '@capacitor/core/dist/esm/util';
import XLSX from 'xlsx';

export default defineComponent({
  name: "ProductionBonusMonthlyListPage",
  components: {
    IonButton,
    // ProductionBonusUserListComponent,
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonSearchbar,
    // IonItem,
    // IonItemDivider,
    // IonDatetime,
    NDatePicker,
    NDataTable,
    NSpace,
  },
  setup() {
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const store = useStore();
    const searchWord = ref<string>('');
    const loadingStatus = ref<boolean>(false);
    const selectedInitDateInTimeStamp = ref(formatDate(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() - 1, 26).toUTCString(), 'YYYY-MM-DD'));
    const selectedEndDateInTimeStamp = ref(formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 25).toUTCString(), 'YYYY-MM-DD'));

    const data = ref<any>([]);
    const selectedInitDateInTimeStampInDesktop = ref<number>(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() - 1, 26).getTime());
    const selectedEndDateInTimeStampInDesktop = ref<number>(new Date(new Date().getFullYear(), new Date().getMonth(), 25).getTime());

    const totalBonuses = (user: User): number => {
      const bonuses: ProductionUserBonus[] = store.getters.allUserBonusMaterialByDates(user.id, selectedInitDateInTimeStampInDesktop.value, selectedEndDateInTimeStampInDesktop.value);
      const dailyBonuses: ProductionDailyUserBonus[] = store.getters.dailyUserBonusByUserIdAndDates(user.id, selectedInitDateInTimeStampInDesktop.value, selectedEndDateInTimeStampInDesktop.value);
      const materialBonus = _.sumBy(bonuses, (bonus: ProductionUserBonus) => {
        if (bonus.status === 'not_achieved')
          return 0;
        return bonus.bonusMaterialRange.money;
      });
      const dailyBonus = _.sumBy(dailyBonuses, (daily: ProductionDailyUserBonus) => {
        if (daily.status === 'achieved')
          return !user.dailyBonusIncome ? 0 : user.dailyBonusIncome;
        return 0
      })
      return materialBonus + dailyBonus;
    }


    const type = {type: 'Monthly'};

    const searchUser = (user: User, word: string): boolean => user.username.toLowerCase().includes(word.toLowerCase());
    const filterUser = (users: User[], word: string): User[] => _.filter(users, (user: User) => searchUser(user, word));
    const sortUser = (users: User[]): User[] => _.sortBy(users, ['name', 'asc']);
    const users = computed(() => sortUser(filterUser(store.getters.allUsers.filter((user: User) => user.appAccess !== "allowed"), searchWord.value)));

    const replaceData = async () => {
      data.value = map(users.value, (user: User) => {
        return {
          key: uuid4(),
          id: user.id,
          name: !user.name ? '' : user.name,
          surname: !user.surname ? '' : user.surname,
          total: totalBonuses(user),
          number: !user.workerNumber ? '' : user.workerNumber,
        };
      });
    };

    const onExportExcel = () => {
      const usefulData = data.value.map((dataNode: any) => {
        return {
          name: dataNode.name,
          surname: dataNode.surname,
          total: dataNode.total,
        }
      });
      const dataInArrays = usefulData.map((node: any) => {
        return Object.keys(node).sort().map((key) => {
          return node[key];
        });
      });
      dataInArrays.unshift(['Nombre', ['Apellidos'], ['Cantidad extra a pagar']]);
      const worksheet = XLSX.utils.aoa_to_sheet(dataInArrays);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, "bonus_mensual");
      XLSX.writeFile(wb, "bonus_entre_" + formatDate(selectedInitDateInTimeStampInDesktop.value / 1000, 'DD/MM/YYYY') + '_y_' +
          formatDate(selectedEndDateInTimeStampInDesktop.value / 1000, 'DD/MM/YYYY') + '.xlsx');
    }

    watch(users, () => replaceData());
    watch(selectedInitDateInTimeStamp, async () => {
      loadingStatus.value = true;
      await useMaterialApi().fetchAll();
      await useUserApi().fetchAll();
      await useBonusApi().fetchByDate(selectedInitDateInTimeStamp.value, selectedEndDateInTimeStamp.value);
      await useBonusApi().fetchAllDailyByDates(selectedInitDateInTimeStamp.value, selectedEndDateInTimeStamp.value);
      await replaceData();
      loadingStatus.value = false;
    });
    watch(selectedEndDateInTimeStamp, async () => {
      loadingStatus.value = true;
      await useMaterialApi().fetchAll();
      await useUserApi().fetchAll();
      await useBonusApi().fetchByDate(selectedInitDateInTimeStamp.value, selectedEndDateInTimeStamp.value);
      await useBonusApi().fetchAllDailyByDates(selectedInitDateInTimeStamp.value, selectedEndDateInTimeStamp.value);
      await replaceData();
      loadingStatus.value = false;
    });

    watch(selectedInitDateInTimeStampInDesktop, async (newSelectedInitDateInTimeStampInDesktop) => {
      loadingStatus.value = true;
      await useMaterialApi().fetchAll();
      await useUserApi().fetchAll();
      await useBonusApi().fetchByDate(String(newSelectedInitDateInTimeStampInDesktop), String(selectedEndDateInTimeStampInDesktop.value));
      await useBonusApi().fetchAllDailyByDates(String(newSelectedInitDateInTimeStampInDesktop), String(selectedEndDateInTimeStampInDesktop.value));
      await replaceData();
      loadingStatus.value = false;
    }, {immediate: true});
    watch(selectedEndDateInTimeStampInDesktop, async (newSelectedEndDateInTimeStampInDesktop) => {
      loadingStatus.value = true;
      await useMaterialApi().fetchAll();
      await useUserApi().fetchAll();
      await useBonusApi().fetchByDate(String(selectedInitDateInTimeStampInDesktop.value), String(newSelectedEndDateInTimeStampInDesktop));
      await useBonusApi().fetchAllDailyByDates(String(selectedInitDateInTimeStampInDesktop.value), String(newSelectedEndDateInTimeStampInDesktop));
      await replaceData();
      loadingStatus.value = false;
    }, {immediate: true});

    onIonViewDidEnter(async () => {
      loadingStatus.value = true;
      await useMaterialApi().fetchAll();
      await useUserApi().fetchAll();
      await useBonusApi().fetchAll();
      await useBonusMaterialApi().fetchAll();
      await useBonusApi().fetchAllDailyByDates(String(selectedInitDateInTimeStampInDesktop.value), String(selectedEndDateInTimeStampInDesktop.value));
      await useBonusApi().fetchByDate(String(selectedInitDateInTimeStampInDesktop.value), String(selectedEndDateInTimeStampInDesktop.value));
      loadingStatus.value = false;
    })

    return {
      onExportExcel,
      loadingStatus,
      selectedInitDateInTimeStampInDesktop,
      selectedEndDateInTimeStampInDesktop,
      isTablet,
      searchWord,
      users,
      type,
      selectedInitDateInTimeStamp,
      selectedEndDateInTimeStamp,
      data,
      columns: [
        {
          title: "Nombre",
          key: "name",
          sorter: 'default',
        },
        {
          title: "Apellidos",
          key: "surname",
          sorter: 'default',
        },
        {
          title: "Número de operario",
          key: "number",
          sorter: 'default',
        },
        {
          title: "Total de bonus conseguidos",
          key: "total",
          sorter: 'default',
        },
      ],

    };
  }
})
